import { Dialog } from 'components/layouts/Dialog/Dialog';
import { AuthDialogProps } from './AuthDialog.types';
import { LoginDialog } from './LoginDialog';
import { SignUpDialog } from './SignUpDialog';
import { SignUpWithEmailDialog } from './SignUpWithEmailDialog';
import { ForgotPasswordDialog } from './ForgotPasswordDialog';
import { UsernameDialog } from './UsernameDialog';
import { useAuthDialog } from './useAuthDialog';
import { useMe } from 'hooks/useMe';
import { AuthGradient } from './AuthGradient';

export const AuthDialog = ({ id }: AuthDialogProps) => {
  const dialog = useAuthDialog();
  const { type, setType, options } = dialog;

  const { isLoggedIn } = useMe();

  const onClose = () => {
    options?.onClose?.();
    dialog.close();
  };

  if (isLoggedIn) {
    return null;
  }

  return (
    <Dialog
      id={id}
      className="overflow-visible max-w-[550px] landscape:max-mediumLarge:max-w-[650px] w-full"
      stayOpenOnOutsideClick={type === 'USERNAME'}
      hideClose={type === 'USERNAME'}>
      <AuthGradient>
        <div className="landscape:max-mediumLarge:min-h-0">
          {type === 'SIGN_UP' && (
            <SignUpDialog
              navigateToLogin={() => setType('LOGIN')}
              navigateToSignupWithEmail={() => setType('SIGN_UP_EMAIL')}
              navigateToUsername={() => setType('USERNAME')}
              hideLoginOption={options?.hideLoginOption}
              title={options?.signUpTitle}
              onSignUpCompleted={options?.onSignUpCompleted}
              onCompleteRedirectPage={options?.onCompleteRedirectPage}
              rewardSoftCurrency={options?.rewardSoftCurrency}
              onClose={onClose}
            />
          )}
          {type === 'LOGIN' && (
            <LoginDialog
              navigateToSignUp={() => setType('SIGN_UP')}
              navigateToForgotPassword={() => setType('FORGOT_PASSWORD')}
              navigateToUsername={() => setType('USERNAME')}
              title={options?.loginTitle}
              onLoginCompleted={options?.onLoginCompleted}
              onCompleteRedirectPage={options?.onCompleteRedirectPage}
              onClose={onClose}
            />
          )}
          {type === 'SIGN_UP_EMAIL' && (
            <SignUpWithEmailDialog
              navigateToLogin={() => setType('LOGIN')}
              navigateToUsername={() => setType('USERNAME')}
              hideLoginOption={options?.hideLoginOption}
              onCompleteRedirectPage={options?.onCompleteRedirectPage}
              onSignUpCompleted={options?.onSignUpCompleted}
              onClose={onClose}
              rewardSoftCurrency={options?.rewardSoftCurrency}
            />
          )}
          {type === 'FORGOT_PASSWORD' && (
            <ForgotPasswordDialog navigateToLogin={() => setType('LOGIN')} onClose={onClose} />
          )}
          {type === 'USERNAME' && (
            <UsernameDialog
              onClose={onClose}
              onSignUpCompleted={options?.onSignUpCompleted}
              onCompleteRedirectPage={options?.onCompleteRedirectPage}
              rewardSoftCurrency={options?.rewardSoftCurrency}
            />
          )}
        </div>
      </AuthGradient>
    </Dialog>
  );
};
