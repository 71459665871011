export const phrases = {
  birthdayInvalid: 'Please enter a valid date',
  commentTooLong: 'Comment too long. Only 256 characters allowed',
  commentTooShort: 'Comment too short. Minimum 1 characters',
  dmNoConversations: 'Search for friends to start a conversation!',
  dmNoSearchResults: 'No results found',
  dmNoFriends: 'Make friends to start chatting and explore HiberWorld together!',
  dnConnectionLost: 'Connection lost',
  dmSearchPlaceholder: 'Search friends',
  emailInvalid: 'Please enter a valid email address',
  emptyField: "This field can't be empty",
  passwordEmpty: 'Please enter your password',
  passwordChooseEmpty: 'Passwords must be at least 4 characters long',
  passwordTooShort: 'Passwords must be at least 4 characters long',
  passwordTooLong: 'Passwords can have up to 256 characters',
  passwordsDontMatch: "The passwords don't match",
  passwordIncorrect: 'Your old password is incorrect',
  projectNameInvalid: "Title can only contain letters, number, emojis, spaces and the characters: _ - , . ( ) | [ ] /'",
  projectDescriptionTooLong: 'Description too long. Only 750 characters allowed',
  projectTooManyTags: 'You can only use five tags',
  searchQueryTooShort: 'Please enter at least 3 characters to search',
  usernameChooseEmpty: 'Usernames must be between 3 and 18 characters long',
  usernameEmpty: 'Please enter a username',
  usernameEmailEmpty: 'Please enter your username or email address',
  usernameInvalid: 'Username can only contain letters, number and the character: _',
  usernameOrEmailEmpty: 'Please enter your username or email address',
  usernameWrongLength: 'Usernames must be between 3 and 18 characters long',
  usernameAlreadyTaken: 'That username is taken. Try another.',
  dayEmpty: 'Enter date',
  monthEmpty: 'Enter month',
  yearEmpty: 'Enter year',
  youtubeInvalid: 'Please enter a valid YouTube channel url',
  instagramTooLong: 'Username must be less than 30 characters',
  instagramInvalid: 'Username can only include letters, numbers, underscores and periods',
  tagsTooMany: "You can't have more than 5 tags",
  tagTooLong: "A tag can't be longer than 30 characters",
  tagInvalid: 'A tag can only contain letters and numbers and no disallowed words',
  twitterTooLong: 'Username must be less than 15 characters',
  twitterInvalid: 'Username can only include letters, numbers and underscores',
  twitchWrongLength: 'Usernames can be 4 - 25 characters long',
  twitchInvalid: 'Username can only include letters, numbers and underscores',
  userDescriptionIllegalCharacters:
    'Description can only contain letters, number, emojis and the characters: -_.,\'"&*!:',
  userDescriptionTooLong: 'Description must be shorter than or equal to 256 characters',
  nameEmpty: 'Please enter a name',
  deviceEmpty: 'Please select a device type',
  contactDescriptionOutOfBounds: 'Description has to be between 15 and 1024 characters long',
  subjectEmpty: 'Please select a subject',
  notAcceptedConsent: 'Please accept the consent in order to contact support',
  tiktokInvalid: 'Username can only include letters, numbers, underscores and periods, and must not end with a period',
  tiktokWrongLength: 'Usernames can be 4 - 24 characters long',
  underAgeText:
    'To join Hiber you need to be 5 or older. But thanks for checking us out, we hope to see you again when you are old enough!',
  userNotValidating: 'Something went wrong when trying to change username, please contact support for help.',
  removeScheduledGame: 'Are you sure you want to remove this scheduled feature?',
  unableToSendEmailVerification:
    'We could not find a email associated with your account. Please reach out to us for further help.',
  emailVerificationSent:
    'It could take a moment for the email to arrive. If you do not receive any email, please check your email trash folder.',
  funTogether: `It's more fun together!`,
  signUpChat: 'Sign up to make friends on HiberWorld and start chatting!',
  signUpForFree: 'SIGN UP FOR FREE',
  alreadyHaveAccount: 'Already have an account? ',
  logIn: 'Log In',
  link: 'Link',
  account: {
    lowercase: 'account?',
    capitalizes: 'Account',
  },
  alreadyHaveA: 'Already have a ',
  hiberWorld: 'HiberWorld',
  forgotPasswordModal: {
    title: 'Forgot Password',
    emailSent: {
      title: 'Email sent!',
      disclaimer:
        'It could take a moment for the email to arrive. If not received, please check your email trash folder.',
    },
    emailOrUsername: 'Enter email or username',
    disclaimer: 'We will send you a reset password link to the provided email address.',
  },
  updateProfile: {
    title: 'Update Profile',
    deleteAccount: 'Delete Account',
    logoutFromAllDevices: 'Logout from all devices',
    settingsHeader: 'Settings for',
  },
  profileForm: {
    title: 'Profile Picture',
    userTitle: 'Title',
    labels: {
      email: 'Email',
      description: 'Description',
      friendsVisibility: 'Who can see my friends',
      achievementsVisibility: 'Who can see my achievements',
      favoritesVisibility: 'Who can see my favorites',
      messagesVisibility: 'Who can see my messages',
      socialMedia: 'Social Media',
      changePassword: 'Change password?',
      blockedUsers: 'Blocked Users',
    },
    selectorValues: {
      noOne: 'No one',
      onlyMe: 'Only me',
      friends: 'Friends only',
      everyone: 'Everyone',
    },
    descriptions: {
      email: 'Email is used to reset your password.',
    },
    placeholders: {
      email: 'Email',
      description: 'Profile description',
      tiktok: 'TikTok Username',
      twitch: 'Twitch Username',
      instagram: 'Instagram Username',
      x: 'X Username',
      youtubeChannel: 'Youtube Channel Url',
      currentPassword: 'Current password',
      newPassword: 'New password',
      confirmPassword: 'Confirm password',
      manageBlockedUsers: 'Manage Blocked Users',
      noBlockedUsers: "You haven't blocked any users",
      cancel: 'Cancel',
      saveChanges: 'Save Changes',
    },
    userTitlePrefix: 'Prefix',
    userTitleSuffix: 'Suffix',
    noSelectedTitle: 'No title',
    userTitleInvalid: 'Invalid user title',
    noAvatarFound: 'No avatar found',
    imageWarning: "Don't use an image of yourself. Inappropriate images will be removed.",
    imageWarningSuspended: 'Suspended users cannot update profile picture',
    backgroundWarningSuspended: 'Suspended users cannot update profile background',
    deleteProfileBackground: {
      confirmation:
        "Are you sure you want to delete your profile background? We'll replace with your Homeworld thumbnail or a default Hiber background image.",
      headline: 'Delete Profile Background',
    },
    deleteProfilePicture: {
      confirmation:
        "Are you sure you want to delete your profile image? We'll replace it with a default Hiber avatar image.",
      headline: 'Delete Profile Image',
    },
    descriptionDisclaimer: "Don't provide any information that can be used to identify you outside of HiberWorld.",
  },
  avatarMenu: {
    guest: {
      signUpToCreate: 'SIGN UP TO CREATE AVATAR',
      linkAccount: 'Link account',
      title: 'Join HiberWorld to create your avatar',
      disclaimer: 'Unlock new outfits, level up and play in style! ',
    },
    title: 'My Avatar',
    changeOutfit: 'Change Outfit',
    createAvatar: 'Create Avatar',
    viewClassic: 'View classic avatars',
    disclaimer: 'Create your own avatar to show off your style',
  },
  achievements: {
    wrongAvatarEquipped: 'Switch to a Ready Player Me avatar to equip this.',
    assetError: 'Opps! something went wrong, please try again.',
    userInfo: {
      level: 'Level',
    },
  },
  celebration: {
    levelUp: 'LEVEL UP',
    dailyComplete: 'DAILY COMPLETE',
    newAchievement: 'NEW ACHIEVEMENT',
  },
  challengesCard: {
    statusText: {
      completed: 'Completed',
    },
    resourcesText: {
      xp: 'XP',
    },
    daily: {
      title: 'Daily Challenges',
      viewAd: 'View ad',
      viewDailyAd: 'View daily ad',
    },
    weekly: {
      title: 'Weekly Challenges',
    },
  },
  shop: {
    buy: 'Buy',
    claim: 'Claim',
    equip: 'Equip',
    equipAndClose: 'Equip & Close',
    equipped: 'Equipped',
    unequippable: 'Unequippable',
    title: 'Shop',
    signUpTitle: 'Sign up for HiberWorld to unlock new items',
    statusLabel: {
      free: 'FREE',
      owned: 'OWNED',
    },
    unavailable: 'UNAVAILABLE',
    soldOut: 'SOLD OUT',
    dialog: {
      button: {
        cancel: 'Cancel',
        confirm: 'Confirm',
      },
      purchase: {
        confirm: 'Confirm your purchase',
      },
    },
    errors: {
      notMatchingAvatarProvider: 'This item requires a Ready Player Me avatar to use.',
    },
    banners: {
      moreItemsComing: 'New items coming soon!',
      merchBanner: {
        title: 'GET YOUR HIBER SWAG',
        buttonMessage: 'SHOP NOW',
      },
    },
    categories: {
      all: 'All',
      outfits: 'Outfits',
      accessories: 'Accessories',
      emotes: 'Emotes',
      exclusive: 'Exclusive',
    },
  },
  product: {
    notFound: 'Could not find product',
    productInfo: {
      signupModalTitle: 'Sign up for HiberWorld to unlock or buy new items',
    },
    buyNow: 'Buy now',
    equipAndClose: 'Equip & Close',
    productDetails: {
      type: 'Type',
      limited: 'LIMITED',
      rpmCopy: 'Unlock this item to use it in HiberWorld and thousands of apps and games supporting Ready Player Me.',
    },
    errors: {
      requiresRPMAvatar: 'This item requires a Ready Player Me avatar to use.',
      checkoutOpenedInNewTab: 'Checkout page has opened in a new tab. Please go there to complete your purchase.',
    },
    shopLink: 'Shop',
  },
  create: {
    tutorialLink: {
      heading: 'New to create mode?',
      description: 'Check out our tutorial page for an introduction to create mode or advanced tips.',
      button: 'View Tutorials',
    },
  },
  authDialog: {
    signUp: {
      defaultHeadline: 'Join HiberWorld today to chat and make friends!',
      likeHeadline: 'Sign up if you want to like this world',
      reportHeadline: 'Sign up if you want to report this world',
      createAccount: 'Create an account to play, create, hang out and much more.',
      loginWall: 'Sign Up and Start Your Gaming Adventure!!',
    },
    login: {
      defaultHeadline: `Log in to HiberWorld`,
      likeHeadline: 'Log in if you want to like this world',
      reportHeadline: 'Log in if you want to report this world',
    },
    guestSignUpInfoHeadline: 'Unlock incredible rewards and showcase your style!',
  },
  engineSignUp: {
    heading: {
      chat: 'Join HiberWorld today to chat and make friends!',
      portal: 'Join HiberWorld today to access new worlds and save your progress!',
      default: 'Join HiberWorld today to chat and make friends!',
    },
    login: 'Login to play, create, hang out and much more.',
    createAccount: 'Create an account to play, create, hang out and much more.',
  },
  loadout: {
    backButton: 'Back',
    avatar: 'Avatar',
    editAvatar: 'Edit Avatar',
    createAvatar: 'Create Avatar',
    emotes: 'Emotes',
    emoteShop: 'Go to Emote shop',
    lookingForEmotes: 'Looking for more emotes?',
    checkOutTheShop: 'Check out the shop',

    pressAgainToEquip: 'Press again to equip',
    emoteAlreadyEquipped: 'Emote is already equipped to this slot',
    pressAnyEmoteToPreview: 'Press any emote to preview and equip',
  },
  categories: {
    noGames: 'No games found',
  },

  suspendUserReasonMissing: 'Please select a reason for suspending the user',
  suspendUserEndAtMissing: 'Please select a date for when the suspension should end',
};
