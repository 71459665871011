import { LogInOrRegister } from 'components/globals/LogInOrRegister/LogInOrRegister';
import { TopBar } from 'views/global/TopBar/TopBar';
import { Suspension } from 'views/dialogs/SuspensionDialog/Suspension';
import { useMe } from 'hooks/useMe';
import { useSearchParam } from 'lib/useSearchParam';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
import { HeadlessAvatarProfileChanger } from 'views/dialogs/AvatarProfilePictureDialog/AvatarProfilePictureDialog';

const Loot = dynamic(() => import('../views/global/Loot'), {
  loading: () => null,
  ssr: false,
});

const PageLoadAuthDialog = dynamic(
  () => import('components/common/AuthDialog/PageLoadAuthDialog').then(module => module.PageLoadAuthDialog),
  {
    loading: () => null,
    ssr: false,
  }
);

export const routesWithoutNavigation = [
  '/developer',
  '/ai/prefab-creator',
  '/ai/world-creator',
  '/ai',
  '404',
  '/embed',
];

const TopBarContainer = ({ hideNavigation }: { hideNavigation?: boolean }) => {
  const me = useMe();
  const hideNavigationParam = useSearchParam('hideNavigation') === 'true';
  const pathname = usePathname() ?? '';
  const suspension = (me.me && me.me.suspension) || null;
  const warning = (me.me && me.me.warning) || null;

  const guestOnStartPage = !me.isLoggedIn && pathname === '/';

  const routeWithoutNavigation = routesWithoutNavigation.some(route => pathname.startsWith(route));

  if (hideNavigation || hideNavigationParam || routeWithoutNavigation || guestOnStartPage) {
    return null;
  }

  const { me: user } = me;

  return (
    <>
      <TopBar />
      <LogInOrRegister />
      <PageLoadAuthDialog />
      <HeadlessAvatarProfileChanger />
      {user && <Loot />}
      {suspension && suspension.seen < 3 && <Suspension suspension={suspension} />}
      {warning && warning.seen < 3 && <Suspension suspension={warning} />}
    </>
  );
};

export default TopBarContainer;
