import {
  Close,
  Friends,
  Globe2,
  Logout,
  Profile,
  Report,
  Settings,
  Sort,
  Tag,
  Trophy,
  User,
  Wallet,
} from '@hiberworld/icons';
import { Link } from 'components/common/Link';
import { Button } from 'components/common/buttons/Button';
import { CircularImage } from 'components/common/images/CircularImage';
import { VerifiedBadge } from 'components/globals/VerifiedBadge';
import { Select } from 'components/inputs/Select/Select';
import { Toggle } from 'components/inputs/Toggle/Toggle';
import { Spacer } from 'components/layouts/Spacer';
import { FeatureTogglesModal } from 'components/legacy/modals/FeatureTogglesModal/FeatureTogglesModal';
import { ButtonText, Heading6, Label } from 'components/typography';
import {
  UserType,
  useBuildInfoQuery,
  usePageQuery,
  useProjectFeedPageQuery,
  useProjectTemplatesQuery,
} from 'generated/graphql';
import { useAmountOfFriendRequests } from 'hooks/useAmountOfFriendRequests';
import { useDive } from 'hooks/useDive';
import { useHasUnclaimedLoot } from 'hooks/useHasUnclaimedLoot';
import { useMe } from 'hooks/useMe';
import { usePressOutside } from 'hooks/usePressOutside';
import { useModal } from 'lib/useModal';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
import { useState } from 'react';
import { twc } from 'react-twc';
import { getPageSlugFromRoute } from 'utils/getPageSlugFromRoute';
import { isModeratorUser } from 'utils/userRoles';
import { useWalletEnabled } from 'views/global/Wallet/useWalletEnabled';
import ProgressBar from '../../../components/common/ProgressBar/ProgressBar';
import { HamburgerButton } from './HamburgerButton/HamburgerButton';
import { useAuthDialog } from 'components/common/AuthDialog/useAuthDialog';
import { phrases } from 'constants/phrases';
import { config } from 'config';

const SmallMenuItemLabel = twc(
  Label
)`flex items-center gap-1 whitespace-nowrap py-[0.175rem] cursor-pointer hover:text-greyscale-veryLightGrey data-[red=true]:text-primary-main`;

const MenuItemLabel = twc(
  ButtonText
)`flex gap-4 items-center py-[0.6rem] px-[1.75rem] hover:bg-navigation-hoverBackground cursor-pointer text-text-default`;

const HamburgerMenuWrapper = twc.div`block absolute w-full mediumSmall:max-w-[320px] max-h-[calc(100dvh-56px)] mediumSmall:max-h-[85vh] top-[56px] left-full mediumSmall:top-[calc(56px+1rem)] bg-navigation-background 
transition-all ease-in-out overflow-y-auto shadow-menu mediumSmall:rounded-lg user-select-none
data-[open=true]:translate-x-[-100%] mediumSmall:data-[open=true]:translate-x-[calc(-100%-32px)] duration-fast pt-[1.75rem] pb-[5px]`;

const SmallGrid = twc.div`grid grid-cols-[1fr_1fr] text-left gap-1 px-7`;

const Line = twc.div`h-[1px] mx-[1.75rem] flex bg-greyscale-dark`;

const WalletModal = dynamic(() => import('views/global/Wallet/WalletModal'), { ssr: false, loading: () => null });

export const HamburgerMenu = () => {
  const { isLoggedIn, me, logout, setAdminUserType, adminUserType } = useMe();
  const featureTogglesModal = useModal(<FeatureTogglesModal />);
  const pathname = usePathname() ?? '';
  const showUnclaimedLootIndicator = useHasUnclaimedLoot();
  const [open, setIsOpen] = useState(false);
  const friendRequestAmount = useAmountOfFriendRequests();
  const hasUnreadFriendRequests = friendRequestAmount > 0;
  const apiBuildInfo = useBuildInfoQuery({ skip: !me?.isEmployee });

  const ref = usePressOutside({
    callback: () => {
      setIsOpen(false);
    },
  });

  const toggleMenu = () => {
    setIsOpen(open => !open);
  };

  const dive = useDive();

  const [showAdminPanel, setShowAdminPanel] = useState(Boolean(adminUserType) && adminUserType !== null);
  const slug = getPageSlugFromRoute(pathname);

  const { refetch: refetchPage } = usePageQuery({
    variables: { slug, previewUserType: adminUserType },
    fetchPolicy: 'network-only',
    skip: true,
  });
  const { refetch: refetchFeedPage } = useProjectFeedPageQuery({
    variables: { slug, preview: Boolean(adminUserType) },
    fetchPolicy: 'network-only',
    skip: true,
  });
  const { refetch: refetchProjectTemplates } = useProjectTemplatesQuery({
    variables: { preview: Boolean(adminUserType) },
    fetchPolicy: 'network-only',
    skip: true,
  });

  const tabAble = open ? 1 : -1;

  const authDialog = useAuthDialog();

  const { enabled: walletEnabled } = useWalletEnabled();
  const walletModal = useModal(<WalletModal />);

  const refetchPageData = () => {
    refetchPage();
    refetchFeedPage();
    refetchProjectTemplates();
  };

  const showWallet = walletEnabled && isLoggedIn;
  const showSettings = isLoggedIn;
  const showModeration = isLoggedIn && isModeratorUser(me);
  const showSeparator = showWallet || showSettings || showModeration;
  const levelProgress = ((me?.currentXp ?? 0) / (me?.xpToNextLevel ?? 1)) * 100 ?? 0;

  return (
    <>
      <HamburgerButton
        showNotificationIndicator={showUnclaimedLootIndicator || hasUnreadFriendRequests}
        expanded={open}
        onClick={e => {
          e.stopPropagation();
          toggleMenu();
        }}
      />
      <HamburgerMenuWrapper
        ref={ref}
        id="hamburger-menu"
        data-testid="hamburger-menu"
        data-cy="hamburgerMenu"
        data-open={open}>
        {isLoggedIn && (
          <>
            <Link
              className="block px-[1.75rem] transition-all ease-out hover:saturate-[150%] hover:brightness-[120%]"
              prefetch={false}
              href={`/user/${me?.username}/`}
              passHref
              onClick={toggleMenu}>
              <div className="flex items-center mb-3 cursor-pointer">
                <CircularImage size={40} src={me?.pictureUrl} data-cy="hamburgerMenuProfileImage" />
                <div
                  data-long-name={me?.username?.length ?? 0 > 10}
                  className="flex flex-col ml-4 gap-[3px] data-[long-name=true]:max-w-[200px]">
                  <div className="flex items-center gap-1" data-cy="hamburgerMenuUsername">
                    <Heading6>{`${me?.username}`}</Heading6>
                    <VerifiedBadge isEmployee={me?.isEmployee} isVerified={me?.isVerified} />
                  </div>
                  {me?.title && (
                    <Label size="medium" className="text-greyscale-mediumGrey">
                      {me.title}
                    </Label>
                  )}
                  <Label
                    size="specialSmall"
                    className="text-greyscale-lightGrey"
                    data-cy="hamburgerMenuLevel">{`Level ${me?.level}`}</Label>
                </div>
              </div>
              <ProgressBar data-cy="hamburgerMenuProgressBar" title={`${me?.currentXp} / ${me?.xpToNextLevel}`}>
                <ProgressBar.Rail className="bg-greyscale-veryDark">
                  <ProgressBar.Progress
                    className="bg-gradient-to-l from-[#BD3FD1] to-[#814CC7]"
                    progress={levelProgress}
                  />
                </ProgressBar.Rail>
              </ProgressBar>
              <Spacer height="0.5rem" />
              <Label size="medium" data-cy="hamburgerMenuNextLevel" style={{ cursor: 'pointer' }}>
                {me?.currentXp} / {me?.xpToNextLevel} XP
              </Label>
            </Link>
            <Spacer height="1rem" />
          </>
        )}

        {!isLoggedIn && (
          <div className="mx-7 mb-4">
            <Link prefetch={false} href={`sign-up?hideMobileLogin=true&hideChat=true`}>
              <Button size="medium" text="SIGN UP FOR FREE" variety="primary" onClick={toggleMenu} />
            </Link>
          </div>
        )}

        {isLoggedIn && (
          <Link prefetch={false} href={`/user/${me?.username}`}>
            <MenuItemLabel size="large" onClick={toggleMenu} tabIndex={tabAble} data-cy="Profile Menu Item">
              <Profile size="1.5rem" title="Profile" /> Profile
            </MenuItemLabel>
          </Link>
        )}

        {isLoggedIn && (
          <Link prefetch={false} href="/creations">
            <MenuItemLabel size="large" data-cy="My Worlds Menu Item" onClick={toggleMenu} tabIndex={tabAble}>
              <Globe2 size="1.5rem" title="My Worlds" /> My Worlds
            </MenuItemLabel>
          </Link>
        )}

        <Link prefetch={false} href="/avatar">
          <MenuItemLabel onClick={toggleMenu} tabIndex={tabAble} size="large" data-cy="Avatar Menu Item">
            <User size="1.5rem" /> Avatar
          </MenuItemLabel>
        </Link>

        <Link prefetch={false} href={isLoggedIn ? `/user/${me?.username}/achievements` : '/achievements'}>
          <MenuItemLabel size="large" data-cy="Achievements Menu Item" onClick={toggleMenu} tabIndex={tabAble}>
            <div className="flex relative">
              <Trophy size="1.5rem" title="Achievements" />
              {showUnclaimedLootIndicator && (
                <div className="flex absolute -right-[2px] w-[10px] h-[10px] bg-text-error rounded-lg" />
              )}
            </div>{' '}
            Achievements
          </MenuItemLabel>
        </Link>

        {!isLoggedIn && (
          <MenuItemLabel
            size="large"
            data-cy="Log In Menu Item"
            onClick={() => {
              authDialog.open('LOGIN', {
                origin: 'hamburger_menu',
                name: 'login',
                description: phrases.authDialog.login.defaultHeadline,
              });
              toggleMenu();
            }}
            tabIndex={tabAble}>
            <Logout size="1.5rem" title="Log in" /> Log In
          </MenuItemLabel>
        )}

        {isLoggedIn && (
          <Link prefetch={false} href={`/user/${me?.username}/friends`}>
            <MenuItemLabel size="large" data-cy="Friends Menu Item" onClick={toggleMenu} tabIndex={tabAble}>
              <div className="flex relative">
                <Friends size="1.5rem" />
                {hasUnreadFriendRequests && (
                  <div
                    data-testid="friend-request-amount-dot"
                    className="flex absolute -right-[2px] w-[10px] h-[10px] bg-text-error rounded-lg"
                  />
                )}
              </div>{' '}
              Friends
            </MenuItemLabel>
          </Link>
        )}

        {showSeparator && <Line className="my-2" />}

        <Link prefetch={false} href="/shop?filter=All">
          <MenuItemLabel size="large" data-cy="Shop Menu Item" onClick={toggleMenu} tabIndex={tabAble}>
            <Tag size="1.5rem" title="Tag" /> Shop
          </MenuItemLabel>
        </Link>

        {showWallet && (
          <MenuItemLabel
            size="large"
            data-cy="Wallets Menu Item"
            tabIndex={tabAble}
            onClick={() => {
              dive.trackModalOpen({ origin: 'hamburger_menu', name: 'wallet', description: '' });
              toggleMenu();
              walletModal.open();
            }}>
            <Wallet size="1.5rem" title="Wallets" /> Wallets
          </MenuItemLabel>
        )}
        {showSettings && (
          <Link prefetch={false} href="/update-profile">
            <MenuItemLabel size="large" data-cy="Settings Menu Item" onClick={toggleMenu} tabIndex={tabAble}>
              <Settings size="1.5rem" title="Settings" /> Settings
            </MenuItemLabel>
          </Link>
        )}

        {showModeration && (
          <Link prefetch={false} href="/moderation/reports">
            <MenuItemLabel size="large" data-cy="Moderation Menu Item" onClick={toggleMenu} tabIndex={tabAble}>
              <Report size="1.5rem" title="Moderation" /> Moderation
            </MenuItemLabel>
          </Link>
        )}

        <Line className="mt-2 mb-4" />

        <SmallGrid>
          <Link prefetch={false} target="_blank" href="https://www.hiber3d.com/about">
            <SmallMenuItemLabel data-cy="About Menu Item" size="medium" onClick={toggleMenu} tabIndex={tabAble}>
              About
            </SmallMenuItemLabel>
          </Link>

          <Link prefetch={false} target="_blank" href="https://hiber3d.com/terms-of-use">
            <SmallMenuItemLabel data-cy="Terms of Use Menu Item" size="medium" onClick={toggleMenu} tabIndex={tabAble}>
              Terms of Use
            </SmallMenuItemLabel>
          </Link>

          <Link prefetch={false} href="/contact" target="_blank">
            <SmallMenuItemLabel size="medium" data-cy="Contact Menu Item" onClick={toggleMenu} tabIndex={tabAble}>
              Contact
            </SmallMenuItemLabel>
          </Link>

          <Link prefetch={false} href="https://hiber3d.com/contentcreatorpolicy" target="_blank">
            <SmallMenuItemLabel
              size="medium"
              data-cy="Content Creator Policy Menu Item"
              onClick={toggleMenu}
              tabIndex={tabAble}>
              Content Creator Policy
            </SmallMenuItemLabel>
          </Link>

          <Link prefetch={false} href="/tutorial">
            <SmallMenuItemLabel size="medium" data-cy="Tutorials Menu Item" onClick={toggleMenu} tabIndex={tabAble}>
              Tutorials
            </SmallMenuItemLabel>
          </Link>

          <Link prefetch={false} href="https://hiber3d.com/privacy-policy" target="_blank">
            <SmallMenuItemLabel
              size="medium"
              data-cy="Privacy Policy Menu Item"
              onClick={toggleMenu}
              tabIndex={tabAble}>
              Privacy Policy
            </SmallMenuItemLabel>
          </Link>

          <Link prefetch={false} href="https://hiber3d.com/faq" target="_blank">
            <SmallMenuItemLabel size="medium" data-cy="FAQ Menu Item" onClick={toggleMenu} tabIndex={tabAble}>
              FAQ
            </SmallMenuItemLabel>
          </Link>
          <Link prefetch={false} href="/cookies?hideCookieBanner=true" target="_blank">
            <SmallMenuItemLabel size="medium" data-cy="Cookie Policy Menu Item" onClick={toggleMenu} tabIndex={tabAble}>
              Cookie Policy
            </SmallMenuItemLabel>
          </Link>
          <Link prefetch={false} href="https://hiber3d.com/news" target="_blank">
            <SmallMenuItemLabel size="medium" data-cy="Press Menu Item" onClick={toggleMenu} tabIndex={tabAble}>
              Press
            </SmallMenuItemLabel>
          </Link>

          {isLoggedIn && isModeratorUser(me) && (
            <Link prefetch={false} href="/boss/start">
              <SmallMenuItemLabel
                size="medium"
                data-cy="Boss Menu Item"
                onClick={toggleMenu}
                data-red
                tabIndex={tabAble}>
                Boss
              </SmallMenuItemLabel>
            </Link>
          )}
        </SmallGrid>
        {isLoggedIn && (
          <SmallGrid className="mt-3">
            <SmallMenuItemLabel
              size="medium"
              data-cy="Log Out Menu Item"
              onClick={() => {
                toggleMenu();
                logout();
              }}
              tabIndex={tabAble}>
              <Logout size={24} /> Log Out
            </SmallMenuItemLabel>
          </SmallGrid>
        )}
        {me?.isEmployee && (
          <div className="flex gap-4 pt-4 px-8">
            <Label size="medium" htmlFor="admin-mode">
              Show admin panel
            </Label>
            <Toggle
              title="admin-panel"
              className="bg-greyscale-dark"
              id="admin-mode"
              checked={showAdminPanel}
              onChange={e => setShowAdminPanel(e.target.checked)}
            />
          </div>
        )}
        <Spacer height="1.75rem" />

        {showAdminPanel && (
          <div className="flex flex-col gap-4 w-full px-2 py-1 bg-greyscale-dark">
            <div className="flex items-center gap-1 px-2">
              <Label size="small" htmlFor="user-role">
                Preview page as
              </Label>
              <Select
                value={adminUserType ?? ''}
                id="user-role"
                onChange={e => setAdminUserType(e.target.value as UserType)}>
                <option value="">Choose type</option>
                <option value={UserType.Guest}>Guest</option>
                <option value={UserType.Nonregistered}>Non-Registered</option>
                <option value={UserType.Registered}>Registered</option>
              </Select>
              <div className="py-1 px-2 cursor-pointer hover:bg-primary-main" role="button">
                <Sort title="Reload" onClick={refetchPageData} />
              </div>
              <div className="py-1 px-2 cursor-pointer hover:bg-primary-main" role="button">
                <Close title="Clear" size="1rem" onClick={() => setAdminUserType(null)} />
              </div>
            </div>
            {me?.isEmployee && (
              <MenuItemLabel
                size="large"
                data-cy="My Feature Toggles Menu Item"
                onClick={() => featureTogglesModal.open()}
                tabIndex={tabAble}>
                <Settings size="1.5rem" /> My Feature Toggles
              </MenuItemLabel>
            )}
          </div>
        )}
        <div className="px-8 pb-2">
          {me?.isEmployee && (
            <div className="flex justify-between">
              <Label size="small" className="text-greyscale-lightGrey">
                Web version: {config.version}
              </Label>
              <Label size="small" className="text-greyscale-lightGrey">
                Api version: {apiBuildInfo.data?.buildInfo.version}
              </Label>
            </div>
          )}
        </div>
      </HamburgerMenuWrapper>
    </>
  );
};
